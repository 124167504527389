<template>
  <div class="settings-content-wrapper">
    <div v-if="!isLoading && user">
      <breadcrumbs :breadcrumbs="breadcrumbs"></breadcrumbs>
      <h1 v-if="id">Edit User</h1>
      <h1 v-else>Create User</h1>
      <p v-if="id">Create user info Set your standard business hours. These times will be used for your appointment calendar.</p>
      <p v-else>Set your standard business hours. These times will be used for your appointment calendar.</p>
      <hr>
      <b-field class="modal-label" label="Time Zone">
        <b-select placeholder="Select time zone" v-model="user.time_zone_id">
          <option
            v-for="timeZone in timeZones"
            :value="timeZone.id"
            :key="timeZone.id">
            {{ timeZone.name }}
          </option>
        </b-select>
      </b-field>
      <b-field class="modal-label" label="Friendly Name" message="A short friendly name for the user.">
        <b-input v-model="user.display_name" type="text" placeholder="Marge"/>
      </b-field>
      <b-field class="modal-label" label="Full Name">
        <b-input v-model="user.name" type="text" placeholder="Margaret Simpson"/>
      </b-field>
      <b-field label="Email" class="modal-label" expanded>
        <b-input v-model="user.email" type="text" placeholder="marge_simpson@aol.com"/>
      </b-field>
      <hr>
      <div class="level is-mobile">
        <div class="level-left"></div>
        <div class="level-right">
          <div class="level-item">
            <a class="button is-light" @click="cancel">Cancel</a>
          </div>
          <div class="level-item">
            <button class="button is-primary" @click="save">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Breadcrumbs from '@/components/Breadcrumbs'

export default {
  name: 'UserEdit',
  data () {
    return {
      breadcrumbs: [
        { name: 'Settings', route: 'settings-home' },
        { name: 'Users', route: 'settings-users' },
        { name: 'Edit User', route: null }
      ],
      isLoading: false,
      user: {
        display_name: '',
        name: '',
        email: '',
        time_zone_id: null
      }
    }
  },
  computed: {
    timeZones () {
      return this.$store.getters['settings/timeZones']
    }
  },
  methods: {
    cancel () {
      this.$router.push({ name: 'settings-home' })
    },
    reloadUser () {
      if (this.id) {
        this.isLoading = true
        this.$store.dispatch('settings/fetchUser', this.id)
          .then((user) => {
            this.user = user
            this.isLoading = false
          })
      }
    },
    save () {
      this.$store.dispatch('settings/saveUser', this.user)
        .then((response) => {
          this.$buefy.toast.open({
            message: 'User saved.',
            type: 'is-success'
          })
          this.$router.push({ name: 'settings-home' })
        })
        .catch(err => {
          const errors = err.response.data.errors
          if (errors) {
            Object.keys(errors).forEach((errorKey) => {
              this.$buefy.toast.open({
                message: errors[errorKey][0],
                type: 'is-danger',
                duration: 5000,
                queue: false
              })
            })
          } else {
            this.$buefy.toast.open({
              message: `Error: ${err.message}`,
              type: 'is-danger',
              duration: 4000,
              queue: false
            })
          }
        })
    }
  },
  mounted () {
    this.reloadUser()
    this.$store.dispatch('settings/fetchTimeZones')
  },
  components: { Breadcrumbs },
  props: ['id']
}
</script>
